/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap'); */
body {
  margin: 0;
  /* font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    /* scrollbar-width: none !important;
      font-family: "Montserrat", sans-serif !important;
      font-optical-sizing: auto !important;
      font-weight: 300 !important;
      font-style: normal !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; */
  /* font-family: "Oswald", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal; */
  font-family: "DM Serif Text", serif !important;
  font-weight: 400;
  font-style: normal;
  /* font-family: "Quicksand", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal; */
}
.dm-serif-text-regular {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}

.dm-serif-text-regular-italic {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: italic;
}
code {
  font-family: "DM Serif Text", serif;
  font-weight: 400;
  font-style: normal;
}
