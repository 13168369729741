.App {
  text-align: center;
  background-color: rgb(239, 238, 238) !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main{
  background-color: rgb(243, 243, 243);
  color: #282c34;
}
.main-image{
  background-image: url("images/freq.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
}
/* .main{
  background-color: #e9f2f9;
  color: #282c34;
} */
/* .bg-pakwach{
  background-color: #323738;
  color: aliceblue;
} */
.bg-pakwach{
  background-color: #0c283d;
  color: aliceblue !important;
}
.bg-pakwach h5{
  color: aliceblue !important;
}
.bg-pakwach h6{
  color: aliceblue !important;
}
.news-focus{
  height: 250px;
}
.bg-pakwach2{
  background-color: #143159;
  color: aliceblue;
}
.bg-pakwach1{
  background-color: rgb(255, 255, 255);
  color: #0a0a0a;
}
h6{
  font-size: small !important;
  text-transform: uppercase !important;
  line-height: 90% !important;
  color: #2c2c2d !important;
  font-weight: 300 !important;
}
.small{
  font-weight: small !important;
}
.smallest{
  font-size: x-small !important;
}
.cursor{
  cursor: pointer !important;
}
h5{
  line-height: 90% !important;
  color: #232324 !important;
}
.link{
  color: #ff4500 !important;
}
.button{
  background-color: #ff4500 !important;
  border: none !important;
  color: whitesmoke !important;
}
a{
  color: #ff4500 !important;
  text-decoration: none !important;
}
.active{
  color: #ff4500 !important;
  /* color: #fa9d74; */
  cursor: pointer;
  font-weight: bolder !important;
}
.bolder{
  font-weight: 800 !important;
}
.tab-item{
  cursor: pointer;
  text-align: start !important;
}
button.navbar-toggler.collapsed{
  border: none !important;
  text-align: center;
}
.nav-toggler{
  border: none !important;
}
.navbar-toggler:focus{
  border: none !important;
  box-shadow: none !important;
}
button:focus:not(:focus-visible) {
  outline: 0;
  border: none !important;
  box-shadow: none !important;
}
.scroll-y {
  overflow-y: auto !important;
  scroll-behavior: smooth !important;
  position: relative !important;
  height: 100vh !important;
  scrollbar-width: none !important;
}
.scroll-highlights{
  overflow-y: auto !important;
  scroll-behavior: smooth !important;
  position: relative !important;
  height: 90vh !important;
  scrollbar-width: none !important;
}
.nav-top{
  position: fixed;
  margin-top: 0%;
  z-index: 1;
  width: 100%;
}
.modal-backdrop{
  opacity: 0 !important;
}
.modal-content{
  border: none !important;
  outline: none !important;
  /* background-color: rgba(136, 44, 44, 0) !important; */
  /* box-shadow: 1px 1px 6px #282c3410 !important; */
}
/* .modal-dialog{
  margin: 0% !important;
} */
.modal-dialog{
  margin-right: 0% !important;
  background-color: rgba(136, 44, 44, 0) !important;
}
.pointer{
  cursor: pointer;
}
.margin-top-plus{
  margin-top: 8% !important;
}
.presenters-index{
  background: -webkit-linear-gradient(top,rgba(0, 0, 0, 0),rgba(22, 22, 22, 0.681),rgba(22, 22, 22, 0.958)), url("images/verrati.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
.kitenge{
  background: -webkit-linear-gradient(right,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.681),rgba(0, 0, 0, 0.958)), url("images/kitenge.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
.presenters-index1{
  background: -webkit-linear-gradient(top,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.681),rgba(0, 0, 0, 0.958)), url("images/Dj\ Cwinya.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
.programs-index{
  background: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),#121212,#121212fa,#121212fa), url("images/mic.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
.women{
  background: -webkit-linear-gradient(right,rgba(0, 0, 0, 0),#070707), url("images/women.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
.bg-gossip{
  background: -webkit-linear-gradient(top,#1c4276b1,#1C4176), url("images/Pakwach\ Fm\ Collateral_BIZ\ CARD.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
.pepsi{
  background: -webkit-linear-gradient(top,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.681),rgba(0, 0, 0, 0.958)), url("images/65a2f4_c2c7b955ef8e4639ae750b03c9b58d7c_mv2_d_1900_1425_s_2.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
.mtn{
  background: -webkit-linear-gradient(top,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.681),rgba(0, 0, 0, 0.958)), url("https://i0.wp.com/mbu.ug/wp-content/uploads/2021/03/MTN-Senkyu-e1617219974439.jpg?fit=813%2C525&ssl=1");
  background-size: cover;
  background-repeat: no-repeat;
  color: aliceblue !important;
}
img{
  border-radius: 5px;
}
.top{
  margin-top: 18%;
}
/* h6.active{
  background: -webkit-linear-gradient(right, #f2632592, #f2632507,#f2632507);
} */
.main-orange{
  background-color: #ff4500;
  color: white !important;
}
input::placeholder{
  font-size: small !important;
}
.bg-darker h6{
  color: rgba(197, 198, 199, 0.509) !important;
}
.bg-darker{
  background-color: #143159;
}